import React, { useEffect, useState } from 'react'
import api from '../../utils/api'
import { useNavigate } from 'react-router-dom';

const index = () => {
  const navigate = useNavigate();
  const [batch, setBatch] = useState([]);

  useEffect(() => {
    const getBatch = async () => {
      const data = await api.getBatch();
      setBatch(data);
    }

    getBatch()
  }, [])

  return (
    <div className="card">
      <div className="card-body">
        <table class="table table-hover">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Batch</th>
              <th scope="col">Start Date</th>
              <th scope="col">End Date</th>
              <th scope="col">Status Offered</th>
              <th scope="col">Program</th>
              <th scope="col">Description</th>
            </tr>
          </thead>
          <tbody>
            {batch?.map((value, key) => (
              <tr onClick={() => navigate(`/batch/${value.batchId}`)} style={{ cursor: 'pointer' }}>
                <th scope="row">{key + 1}</th>
                <td>{value?.batch}</td>
                <td>{value?.dateStart}</td>
                <td>{value?.dateEnd}</td>
                <td>
                  <span class={`badge rounded-pill text-bg-${value?.statusOfferedProgram ? 'success' : 'danger'}`}>
                    {value?.statusOfferedProgram ? 'Running' : 'Closed'}
                  </span>
                </td>
                <td>{value?.program?.name}</td>
                <td>{value?.program?.descriptions}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default index;
