import React from 'react';
import { Routes, Route, Outlet } from 'react-router-dom';

// Components
import Navbar from './Components/Navbar';
import Footer from './Components/Footer';

import Index from './Pages/Index';
import Batch from './Pages/Batch';
import Program from './Pages/Program';
import Employees from './Pages/Employees';
import Sidebar from './Components/Sidebar';
import ProgramDetail from './Pages/Program/ProgramDetail';
import ProgramThird from './Pages/Program/ProgramThird';
import BatchDetail from './Pages/Batch/BatchDetail';

function AdminLayout() {
  return (
    <div className="container-fluid">
      <div className="row">
        <Sidebar />
        <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4 p-4 py-md-4">
          <Outlet />
        </main>
      </div>
    </div>
  );
}

function App() {
  return (
    <>
      <header>
        <Navbar />
      </header>
      <main>
        <Routes>
          <Route element={<AdminLayout />} path="/">
            <Route index element={<Index />} />
            <Route path='batch'>
              <Route index element={<Batch />} />
              <Route path=':id' element={<BatchDetail />} />
            </Route>
            <Route path='program'>
              <Route index element={<Program />} />
              <Route path=':id' element={<ProgramDetail />} />
              <Route path='third' element={<ProgramThird />} />
            </Route>
            <Route path='employees' element={<Employees />} />
          </Route>
        </Routes>
      </main>
      <footer>
        <Footer />
      </footer>
    </>
  );
}

export default App;
