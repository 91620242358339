import React from 'react';
import { useParams } from 'react-router-dom';

const ProgramDetail = () => {
  let { id } = useParams();
  return (
    <div className="row">
      <div className="col-6">
        <div className="card">
          <div className="card-body">

          </div>
        </div>
      </div>

      <div className="col-6">
        <div className="card">
          <div className="card-body">

          </div>
        </div>
      </div>
    </div>
  )
}

export default ProgramDetail