import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import api from '../../utils/api';

const BatchDetail = () => {
  let { id } = useParams();
  const [batch, setBatch] = useState({});

  useEffect(() => {
    const getThird = async () => {
      const data = await api.getBatchById({ id });
      setBatch(data);
      console.log(data);
    }

    getThird();
  }, [id]);

  return (
    <div className='card'>
      <div className="card-body">
        <div class="d-flex justify-content-between">
          <h5>Batch: {batch.batch}</h5>
          <h5>Status:{' '}
            <span class={`badge rounded-pill text-bg-${batch?.statusOfferedProgram ? 'success' : 'danger'}`}>
              {batch?.statusOfferedProgram ? 'Running' : 'Closed'}
            </span>
          </h5>

        </div>

        <table class="table table-hover">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Name</th>
              <th scope="col">Descriptions</th>
              <th scope="col">Start Date</th>
              <th scope="col">End Date</th>
              <th scope="col">Active Status</th>
            </tr>
          </thead>
          <tbody>
            {batch?.sections?.map((value, key) => (
              <tr style={{ cursor: 'default' }}>
                <th scope="row">{key + 1}</th>
                <td>{value?.title}</td>
                <td>{value?.descriptions}</td>
                <td>{value?.startDate}</td>
                <td>{value?.endDate}</td>
                <td>
                  <span class={`badge rounded-pill text-bg-${value?.activeStatus ? 'success' : 'danger'}`}>
                    {value?.activeStatus ? 'Running' : 'Closed'}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default BatchDetail