import React, { useEffect, useState } from 'react';
import api from '../../utils/api';
import { useNavigate } from 'react-router-dom';

const index = () => {
  const navigate = useNavigate();
  const [firstProgram, setFirstProgram] = useState([]);

  useEffect(() => {
    const getFirstProgram = async () => {
      const result = await api.getAllFirstThierProgram();
      setFirstProgram(result);
    }

    getFirstProgram()
  }, []);
  return (
    <div class="d-flex flex-column">
      {/* flex-md-row  p-4 gap-4 py-md-5 */}
      <div className="card">
        <div className="card-body">
          <div className="table-responsive">
            <table class="table table-hover">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Nama</th>
                  <th scope="col">Status</th>
                  <th scope="col">Deskripsi</th>
                  <th scope="col">Owner</th>
                </tr>
              </thead>
              <tbody>
                {firstProgram?.map((value, key) => (
                  <tr onClick={() => navigate(`/program/${value.firstTierProgramId}`)} style={{ cursor: 'pointer' }}>
                    <th scope="row">{key + 1}</th>
                    <td>{value?.name}</td>
                    <td><span class={`badge rounded-pill text-bg-${value?.status ? 'success' : 'danger'}`}>Running</span></td>
                    <td>{value?.description}</td>
                    <td>{value?.manageBy?.firstName} {value?.manageBy?.lastName}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default index;
