import React, { useState, useEffect } from 'react'
import api from '../../utils/api'

const index = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
    const getEmployees = async () => {
      const result = await api.getEmployees({ page: 1, pageSize: 5 });
      console.log(result);
      setData(result);
    }

    getEmployees();
  }, []);
  return (
    <div>index</div>
  )
}

export default index