import React, { useEffect, useState } from 'react'
import api from '../../utils/api'

const ProgramThird = () => {
  const [third, setThird] = useState([]);

  useEffect(() => {
    const getThird = async () => {
      const result = await api.getAllThirdThierProgram();
      console.log(result);
      setThird(result);
    }

    getThird();
  }, []);

  return (
    <>
      <div>ProgramThird</div>
      <table class="table">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Nama</th>
            <th scope="col">Deskripsi</th>
          </tr>
        </thead>
        <tbody>
          {third.map((value, key) => (
            <tr>
              <th scope="row">{key + 1}</th>
              <td>{value.name}</td>
              <td>{value.descriptions}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  )
}

export default ProgramThird