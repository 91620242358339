const api = (() => {
  const {
    REACT_APP_PROTOCOL,
    REACT_APP_HOST,
    REACT_APP_PORT,
  } = process.env;
  const BASE_URL = `${REACT_APP_PROTOCOL}://${REACT_APP_HOST}:${REACT_APP_PORT}`;

  function putAccessToken(token) {
    localStorage.setItem('accessToken', token);
  }

  function getAccessToken() {
    return localStorage.getItem('accessToken');
  }

  function putRefreshToken(token) {
    return localStorage.setItem('refreshToken', token);
  }

  function getRefreshToken() {
    return localStorage.getItem('refreshToken');
  }

  function deleteToken() {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
  }

  async function _fetchWithAuth(url, options = {}) {
    return fetch(url, {
      ...options,
      headers: {
        ...options.headers,
        Authorization: `Bearer ${getAccessToken()}`,
      },
    });
  }

  async function register({ name, email, password }) {
    const response = await fetch(`${BASE_URL}/users`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name,
        email,
        password,
      }),
    });

    const responseJson = await response.json();
    const { status, message } = responseJson;

    if (status !== 'success') {
      throw new Error(message);
    }

    return { status };
  }

  async function login({ email, password }) {
    const response = await fetch(`${BASE_URL}/authentications`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
        password,
      }),
    });

    const responseJson = await response.json();
    const { status, message, data } = responseJson;

    if (status !== 'success') {
      throw new Error(message);
    }

    return data;
  }

  async function refreshToken() {
    const response = await fetch(`${BASE_URL}/authentications`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        refreshToken: `${getRefreshToken()}`,
      }),
    });

    const responseJson = await response.json();
    const { status, message, data } = responseJson;

    if (status !== 'success') {
      throw new Error(message);
    }
    putAccessToken(data.accessToken);
  }

  async function getBatch() {
    const response = await _fetchWithAuth(`${BASE_URL}/batch`);

    const responseJson = await response.json();
    const { status, message, data } = responseJson;

    if (status !== 'success') {
      throw new Error(message);
    }

    return data;
  }

  async function getBatchById({ id }) {
    const response = await _fetchWithAuth(`${BASE_URL}/batch/${id}`);

    const responseJson = await response.json();
    const { status, message, data } = responseJson;

    if (status !== 'success') {
      throw new Error(message);
    }

    return data;
  }

  async function getSectionsProgram() {
    const response = await _fetchWithAuth(`${BASE_URL}/sections/program`);

    const responseJson = await response.json();
    const { status, message, data } = responseJson;

    if (status !== 'success') {
      throw new Error(message);
    }

    return data;
  }

  async function getEmployees({ pageSize, page }) {
    const response = await _fetchWithAuth(`${BASE_URL}/bcf/employees?pageSize=${pageSize}&page=${page}`);

    const responseJson = await response.json();
    const { status, message, data } = responseJson;

    if (status !== 'success') {
      throw new Error(message);
    }

    return data;
  }

  // PROGRAM
  async function getAllFirstThierProgram() {
    const response = await _fetchWithAuth(`${BASE_URL}/program/first-tier`);

    const responseJson = await response.json();
    const { status, message, data } = responseJson;

    if (status !== 'success') {
      throw new Error(message);
    }

    return data;
  }

  async function getFirstThierProgramById({ id }) {
    const response = await _fetchWithAuth(`${BASE_URL}/program/first-tier/${id}`);

    const responseJson = await response.json();
    const { status, message, data } = responseJson;

    if (status !== 'success') {
      throw new Error(message);
    }

    return data;
  }

  async function getAllSecondThierProgram() {
    const response = await _fetchWithAuth(`${BASE_URL}/program/second-tier`);

    const responseJson = await response.json();
    const { status, message, data } = responseJson;

    if (status !== 'success') {
      throw new Error(message);
    }

    return data;
  }

  async function getSecondThierProgramById({ id }) {
    const response = await _fetchWithAuth(`${BASE_URL}/program/second-tier/${id}`);

    const responseJson = await response.json();
    const { status, message, data } = responseJson;

    if (status !== 'success') {
      throw new Error(message);
    }

    return data;
  }

  async function getAllThirdThierProgram() {
    const response = await _fetchWithAuth(`${BASE_URL}/program/third-tier`);

    const responseJson = await response.json();
    const { status, message, data } = responseJson;

    if (status !== 'success') {
      throw new Error(message);
    }

    return data;
  }

  async function getThirdThierProgramById({ id }) {
    const response = await _fetchWithAuth(`${BASE_URL}/program/third-tier/${id}`);

    const responseJson = await response.json();
    const { status, message, data } = responseJson;

    if (status !== 'success') {
      throw new Error(message);
    }

    return data;
  }

  return {
    putAccessToken,
    getAccessToken,
    putRefreshToken,
    getRefreshToken,
    refreshToken,
    deleteToken,
    register,
    login,
    // BATCH
    getBatch,
    getBatchById,
    // SECTIONS
    getSectionsProgram,
    getEmployees,
    // PROGRAM
    getAllFirstThierProgram,
    getFirstThierProgramById,
    getAllSecondThierProgram,
    getSecondThierProgramById,
    getAllThirdThierProgram,
    getThirdThierProgramById,
  };
})();

export default api;
